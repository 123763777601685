const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY

const firebaseConfig = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: '558076121145',
  appId: '1:558076121145:web:a31fc57cf9f3aaae897de8',
  measurementId: 'G-D82NR8R9N0'
}

export default firebaseConfig
