const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY

const firebaseConfig = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: '107691324389',
  appId: '1:107691324389:web:dcd8f0b94392f539ac350c',
  measurementId: 'G-SL7T7Q6M9V'
}

export default firebaseConfig
