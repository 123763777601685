export const menuItems = [
  {
    name: 'Transport',
    url: 'https://rynoinsurance.com.au/transport-insurance'
  },
  {
    name: 'Car',
    url: 'https://rynoinsurance.com.au/enthusiast-car-insurance'
  },
  {
    name: 'Claims',
    url: 'https://rynoinsurance.com.au/claims'
  },
  {
    name: 'Our Story',
    url: 'https://rynoinsurance.com.au/about'
  },
  {
    name: 'News',
    url: 'https://rynoinsurance.com.au/blog'
  },
  {
    name: 'Contact',
    url: 'https://rynoinsurance.com.au/contact-us'
  }
]

export const widget1Items = [
  {
    name: 'Transport Insurance',
    url: 'https://rynoinsurance.com.au/transport-insurance'
  },
  {
    name: 'Car Insurance',
    url: 'https://rynoinsurance.com.au/enthusiast-motor-insurance'
  },
  {
    name: 'Claims',
    url: 'https://rynoinsurance.com.au/claims'
  },
  {
    name: 'Quotes',
    url: 'https://rynoinsurance.com.au/quote'
  },
  {
    name: 'Our Story',
    url: 'https://rynoinsurance.com.au/about'
  },
  {
    name: 'News',
    url: 'https://rynoinsurance.com.au/blog'
  },
  {
    name: 'Contact',
    url: 'https://rynoinsurance.com.au/contact-us'
  }
]

export const widget2Items = [
  {
    name: 'Complaints Policy',
    url: 'https://rynoinsurance.com.au/wp-content/uploads/2021/08/Ryno_Complaints-Policy_June-2021.pdf'
  },
  {
    name: 'Domestic Financial Abuse Policy',
    url: 'https://rynoinsurance.com.au/wp-content/uploads/2021/06/Ryno_Domestic-and-Financial-Abuse-Policy_June-2021-1.pdf'
  },
  {
    name: 'Financial Services Guide',
    url: 'https://rynoinsurance.com.au/wp-content/uploads/2024/05/EWG-FSG-v27-May-2024.pdf'
  },
  {
    name: 'Insurance Brokers Code of Practice',
    url: 'https://rynoinsurance.com.au/code-of-practice'
  },
  {
    name: 'Privacy Statement',
    url: 'https://rynoinsurance.com.au/wp-content/uploads/2024/03/EWG_Privacy-Policy_V8.0-0224.pdf'
  },
  {
    name: 'Website Terms of Use',
    url: 'https://rynoinsurance.wpengine.com/wp-content/uploads/2021/06/Ryno_Website-Terms-of-Use_June-2021.pdf'
  }
]
