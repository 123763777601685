import { colors } from '@material-ui/core'
import palette from 'theme/palette'

export default {
  button: {
    // '&:hover': {
    //   backgroundColor: palette.primary.light
    // }
  },
  root: {
    '&$selected': {
      backgroundColor: palette.primary.light
    }
  }
}
