export default {
  gutterBottom: {
    marginBottom: 8
  },
  root: {
    color: '#363b3f !important',
    fontFamily: "'DM Sans', sans-serif !important",
    fontWeight: 'bolder !important'
  },
  colorSecondary: {
    color: '#f8ad40 !important'
  },
  body2: {
    fontWeight: 'normal !important',
    fontSize: '0.8rem',
    textAlign: 'justify'
  },
  body1: {
    fontWeight: 'normal !important',
    fontSize: '0.9rem'
  }
}
