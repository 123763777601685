import firebase from 'firebase'
import _ from 'lodash'

import { atom, selector, selectorFamily } from 'recoil'

//import { firestore } from './firebase'

export const formName = atom({
  key: 'formName',
  default: undefined
})

export const currentPage = atom({
  key: 'currentPage',
  default: 0
})

export const forms = selector({
  key: 'forms',
  get: async ({ get }) => {
    const db = firebase.firestore()
    return (await db.collection('forms').get()).docs.map(d => ({ id: d.id }))
  }
})

export const formState = selector({
  key: 'formState',
  get: async ({ get }) => {
    const db = firebase.firestore()
    const formId = get(formName)
    // console.log(formId)
    const ref = await db.collection('forms').doc(formId).get()

    if (!ref.exists) return []
    return ref.data()
  }
})

export const pageByIdState = selectorFamily({
  key: 'pageByIdState',
  get:
    id =>
    ({ get }) => {
      const form = get(formState)
      return form.pages.find(p => p.id == id)
    }
})

export const pageState = selector({
  key: 'pageState',
  get: async ({ get }) => {
    const pageId = get(currentPage)
    const form = get(formState)
    const page = form.pages[pageId]

    return page
  }
})

export const currentAnswerState = selector({
  key: 'currentAnswerState',
  get: async ({ get }) => {
    const page = get(pageState)
    const answers = get(answersFormState)

    return answers.find(d => d.page == page.id) || {}
  }
})

export const processingPage = selector({
  key: 'processingPage',
  get: async ({ get }) => {
    const pageId = get(currentPage)
    const form = get(formState)
    const processing = (pageId * 100) / (form?.pages?.length - 1 ?? 1)
    return processing ?? 0
  }
})

export const pageHistoryState = atom({
  key: 'pageHistoryState',
  default: []
})

export const answersFormState = atom({
  key: 'answersFormState',
  default: []
})
export const adsState = atom({
  key: 'adsState',
  default: {}
})
