import { colors } from '@material-ui/core'

export default {
  contained: {
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    backgroundColor: colors.cyan[100],
    '&:hover': {
      backgroundColor: colors.cyan[600]
    },
    borderRadius: '8px'
  },
  root: {
    color: '#363b3f',
    fontFamily: "'DM Sans', sans-serif",
    fontWeight: 'bold'
  }
}
