import { colors } from '@material-ui/core'

const white = '#FFFFFF'

export default {
  primary: {
    contrastText: white,
    dark: '#3970d8',
    main: '#3dbbea',
    light: colors.lightBlue[100],
    darkLight: '#363b3f'
  },
  secondary: {
    contrastText: white,
    dark: '#f05e22',
    main: '#f8ad40',
    light: colors.amber.A400,
    darkLight: '#363b3f'
  },

  default: { contrastText: white, dark: colors.brown[900], main: colors.brown[500], light: colors.brown[100] },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
}
