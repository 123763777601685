import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import PhoneIcon from '@material-ui/icons/Phone'
import { widget1Items, widget2Items } from 'constants'

export const BaseFooter = props => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <footer
      style={{ backgroundImage: isMobile ? '' : 'url(/footer-ryno-insurance.png)' }}
      className="bg-accent-gray bg-no-repeat bg-none bg-center bg-scroll bg-cover py-8 md:py-12 flex flex-col w-full justify-between items-center md:min-h-[400px] px-5 lg:px-0 gap-5 md:gap-0">
      <div className="max-w-[1100px] mx-auto w-full">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 lg:col-span-3">
            <ul className="list-none p-0 m-0 space-y-2">
              {widget1Items.map((wItem, wIndex) => (
                <li key={wIndex}>
                  <a className="text-white hover:underline transition text-sm" href={wItem.url}>
                    {wItem.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-12 lg:col-span-3">
            <ul className="list-none p-0 m-0 space-y-2">
              {widget2Items.map((wItem, wIndex) => (
                <li key={wIndex}>
                  <a className="text-white hover:underline transition text-sm" href={wItem.url}>
                    {wItem.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-6">
            <p className="text-sm text-white m-0">Receive the latest news, events, featured stories, and more by signing up to our newsletter.</p>
          </div>
        </div>
      </div>

      <div className="max-w-[1100px] mx-auto text-white w-full">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="flex flex-col md:flex-row md:items-center">
            <img className="w-[130px]" src="/white-logo.png" alt="logo" />

            <p className="text-[10px]">
              © 2024 Ryno Insurance. All Rights Reserved.
              <br />
              Ryno Insurance is a specialist division of East West
              <br />
              Insurance Brokers Pty Ltd ABN 83 010 630 092,
              <br />
              Australian Financial Services License No. 230041.
            </p>
          </div>

          <div className="md:text-right">
            <div className="inline-flex items-center space-x-2">
              <PhoneIcon />
              <a href="tel:1300650670" className="text-white text-lg font-semibold">
                1 300 650 670
              </a>
            </div>

            <p className="text-sm font-semibold">
              Business Hours:
              <br />
              Monday to Friday
              <br />
              8.30AM - 5.00PM <span style={{ fontSize: 10 }}>AEST</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
