import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './style/index.css'
import { Loading } from 'components/loading'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './theme'
import firebase from 'utils/firebase'

import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Beforeunload } from 'react-beforeunload'

export const history = createBrowserHistory()

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById('root')
)
