import React, { lazy } from 'react'
// import Home from 'pages/Home'
import { Redirect } from 'react-router-dom'

export default [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('pages/OnlineQuotation'))
  },
  {
    path: '/Vehicle',
    exact: true,
    component: lazy(() => import('pages/OnlineQuotation'))
  },
  {
    path: '/Transport',
    exact: true,
    component: lazy(() => import('pages/Transport'))
  },
  {
    path: '/Rides',
    exact: true,
    component: lazy(() => import('pages/Rides'))
  },
  {
    path: '/BrokerInput/Vehicle',
    exact: true,
    component: lazy(() => import('pages/Broker/OnlineQuotation'))
  },
  {
    path: '/Transport/short-form',
    exact: true,
    component: lazy(() => import('pages/Transport/short-form'))
  },
  {
    path: '/vehicle/short-form',
    exact: true,
    component: lazy(() => import('pages/OnlineQuotation/short-form'))
  },
  {
    component: lazy(() => import('pages/Error/Error404'))
  }
]
